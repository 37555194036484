import { Alert, DropZone, Flex, Loader, Text } from '@aws-amplify/ui-react'
import { DataStore } from 'aws-amplify/datastore'
import { FunctionComponent, useState } from 'react'
import FinancialInput from '../components/FinancialInput'
import { useOpportunity } from '../contexts/opportunityContext'
import GlassCard from '../figma-components/GlassCard'
import { FinancialDataV2, Opportunity } from '../models'
import { restAPIRequest } from '../services/restApiRequest'

const FinancialPage: FunctionComponent = () => {
  const [isLoading, setIsLoaded] = useState(false)
  const [error, setError] = useState('')
  const opportunity = useOpportunity()
  // TODO: remove this check
  if (!opportunity) return null

  async function submit(data: FinancialDataV2[]) {
    if (!opportunity) return alert('Select a valid target company')

    try {
      await DataStore.save(
        Opportunity.copyOf(opportunity, (updated) => {
          Object.assign(updated, {
            financialDataV2: data,
          })
        })
      )
    } catch (error: any) {
      console.error('marketMapCompSet: Error saving to datastore', error.message, error)
      alert('Error saving, check inputs and try again')
    }
  }

  if (!opportunity?.financialDataV2) {
    return (
      <GlassCard header='Financials' level={0}>
        {error && (
          <Alert variation='error'>
            <Text>Issue uploading your data, please try again</Text>
          </Alert>
        )}
        <DropZone
          placeholder='Drag and drop or click to upload'
          onDrop={async (e) => {
            setIsLoaded(true)
            try {
              await restAPIRequest({
                path: `deals/${opportunity.id}/financials/upload`,
                method: 'post',
              })
            } catch (error: any) {
              setError(error?.message || error || 'unknown')
              console.error('Error uploading financials', error)
            } finally {
              setIsLoaded(false)
            }
          }}
        >
          <Flex direction='column' alignItems='center'>
            {isLoading ? <Loader></Loader> : <Text>Drag data here</Text>}
          </Flex>
        </DropZone>
      </GlassCard>
    )
  }
  return <FinancialInput submit={submit} initState={(opportunity.financialDataV2 || []).map((e) => ({ ...e }))} />
}

export default FinancialPage
