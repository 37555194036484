import { Button } from '@aws-amplify/ui-react'
import { FunctionComponent, useState } from 'react'
import { restAPIRequest } from '../services/restApiRequest'
import { ExcelIcon } from '../ui-components'

interface ExportButtonProps {
  filename: string
  type: 'trxModel' | 'fin'
  data: any
}

const saveFile = async (blob, filename) => {
  const a = document.createElement('a')
  a.download = filename
  a.href = URL.createObjectURL(blob)
  a.addEventListener('click', (e) => {
    setTimeout(() => URL.revokeObjectURL(a.href), 30 * 1000)
  })
  a.click()
}

async function requestExport(data: any, type: string, filename: string) {
  const res = await restAPIRequest<string>({
    body: data,
    path: `exports/${type}`,
    method: 'post',
    responseType: 'blob',
  })
  const blob = new Blob([res], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
  saveFile(blob, filename)
}

const ExportButton: FunctionComponent<ExportButtonProps> = ({ filename, type, data }) => {
  const [saving, setSaving] = useState(false)
  return (
    <Button
      isLoading={saving}
      loadingText='Exporting...'
      variation='primary'
      size='small'
      gap='0.5rem'
      className='excel'
      onClick={async () => {
        if (saving) return
        setSaving(true)
        await requestExport(data, type, filename)
          .catch((e) => {
            const enc = new TextDecoder('utf-8')
            const msg = JSON.parse(enc.decode(e?.response?.data || '{"error": ""}'))?.error
            alert(`Error generating export. Fix inputs ${msg} and try again`)
          })
          .finally(() => {
            setSaving(false)
          })
      }}
    >
      <ExcelIcon />
      Export to Excel
    </Button>
  )
}

export default ExportButton
