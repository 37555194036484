import { Button, Flex, ScrollView } from '@aws-amplify/ui-react'
import { CellChange, Column, DefaultCellTypes, NumberCell, ReactGrid, Row } from '@silevis/reactgrid'
import '@silevis/reactgrid/styles.css'
import { FunctionComponent, useState } from 'react'
import { useLocation } from 'react-router-dom'
import GlassCard from '../figma-components/GlassCard'
import { FinancialDataV2 } from '../models'
import '../styles/dashboard.css'
import '../styles/grid.scss'
import '../styles/table.css'
import CompanyHeader from './CompanyHeader'
import { CurrencyCell, CurrencyCellTemplate } from './CurrencyCell'
import { HeaderCell, HeaderCellTemplate } from './HeaderCell'
import { HorizontalChevronCell, HorizontalChevronCellTemplate } from './HorizontalChevronCell'
import { MultipleCellTemplate } from './MultipleCell'
import { PercentCell, PercentCellTemplate } from './PercentCell'
import ExportButton from './ExportButton'

const nonEditable = (cell: RowCells): RowCells => ({
  ...cell,
  nonEditable: true,
})

const getColumns = (): (Column & { headerName?: string })[] => {
  const currentYear = new Date().getFullYear()
  const columns: (Column & { headerName?: string })[] = [{ columnId: 'headers', width: 250, headerName: 'Period' }]

  for (let i = currentYear - 5; i <= currentYear + 5; i++) {
    columns.push({ columnId: `${i}-Q1`, headerName: `Q1 ${i}` })
    columns.push({ columnId: `${i}-Q2`, headerName: `Q2 ${i}` })
    columns.push({ columnId: `${i}-Q3`, headerName: `Q3 ${i}` })
    columns.push({ columnId: `${i}-Q4`, headerName: `Q4 ${i}` })
    columns.push({ columnId: `${i}`, headerName: `CY ${i}` })
  }

  columns.push({ columnId: 'ltm', headerName: 'LTM' })
  columns.push({ columnId: 'ntm', headerName: 'NTM' })

  return columns
}

function headerRow(cols): Row<HeaderCell> {
  return {
    rowId: 'header',
    cells: cols.map((col) => {
      const { columnId, headerName } = col
      const text = headerName
      const hasChildren = columnId.length === 4
      const isYear = columnId.length === 4
      const isExpanded = cols.filter((col) => col.columnId.includes(columnId)).length > 1
      return { type: 'horizontalChevron', columnId: columnId, text, hasChildren, isExpanded, className: isYear ? 'year' : '' }
    }),
    height: 38,
  }
}

const keyToHeader = {
  totalRevenue: { displayName: 'Total Revenue ($)', type: 'currencyCell' },
  totalRevGrowthPercent: { displayName: 'Total Rev Growth (%)', type: 'percentCell' },
  totalARR: { displayName: 'Total ARR ($)', saasGridNameOverride: 'Ending ARR', type: 'currencyCell' },
  arrGrowthPercent: { displayName: 'ARR Growth (%)', type: 'percentCell' },
  totalGrossProfit: { displayName: 'Total Gross Profit ($)', type: 'currencyCell' },
  grossMarginPercent: { displayName: 'Gross Margin (%)', type: 'percentCell' },
  totalEBITDA: { displayName: 'Total EBITDA ($)', type: 'currencyCell' },
  eBITDAMarginPercent: { displayName: 'EBITDA Margin (%)', type: 'percentCell' },
  totalFCF: { displayName: 'Total FCF ($)', type: 'currencyCell' },
  fcfMarginPercent: { displayName: 'FCF Margin (%)', type: 'percentCell' },
  gnaAsPercentOfRev: { displayName: 'G&A as % of Rev', type: 'percentCell' },
  snmAsPercentOfRev: { displayName: 'S&M as % of Rev', type: 'percentCell' },
  rndAsPercentOfRev: { displayName: 'R&D as % of Rev', type: 'percentCell' },
  grossRetentionPercent: { displayName: 'Gross Retention %', saasGridNameOverride: 'Gross Dollar Retention (Trailing Period)', type: 'percentCell' },
  netRetentionPercent: { displayName: 'Net Retention %', saasGridNameOverride: 'Net Dollar Retention (Trailing Period)', type: 'percentCell' },
  salesEfficiencyMultiple: { displayName: 'Sales Efficiency', saasGridNameOverride: 'New Sales ARR / S&M Expenses', type: 'multipleCell' },
  netNewARR: { displayName: 'Net New ARR ($)', saasGridNameOverride: 'Net New ARR', type: 'currencyCell' },
  netNewARRGrowthPercent: { displayName: 'Net New ARR Growth %', saasGridNameOverride: 'Net New ARR - Growth', type: 'percentCell' },
  arrPerEmployee: { displayName: 'ARR $ / Employee', saasGridNameOverride: 'ARR per Employee', type: 'currencyCell' },
  annualizedOpexPerEmployee: { displayName: 'Opex $ / Employee', type: 'currencyCell' },
  burnMultiple: { displayName: 'Burn Multiple', saasGridNameOverride: 'Burn Multiple', type: 'multipleCell' },
  operatingIncomePercent: { displayName: 'Operating Income %', type: 'percentCell' },
  ruleOf40LTMFCF: { displayName: 'Rule of 40 (LTM FCF)', type: 'percentCell' },
  ruleOf40LTMEbitda: { displayName: 'Rule of 40 (LTM EBITDA)', type: 'percentCell' },
  salesAndMarketingYield: { displayName: 'Sales and Marketing Yield', type: 'percentCell' },
  implied5yrLTVPerCAC: { displayName: 'Implied 5yr LTV/CAC', saasGridNameOverride: 'LTV / CAC', type: 'multipleCell' },

  startingARR: { displayName: 'Starting ARR', type: 'currencyCell' },
  newSalesARR: { displayName: 'New Sales ARR', type: 'currencyCell' },
  expansionARR: { displayName: 'Expansion ARR', type: 'currencyCell' },
  resurrectedARR: { displayName: 'Resurrected ARR', type: 'currencyCell' },
  contractionARR: { displayName: 'Contraction ARR', type: 'currencyCell' },
  churnedARR: { displayName: 'Churned ARR', type: 'currencyCell' },
  netNewARRYoYGrowthPercent: { displayName: 'Net New ARR - YoY Growth', type: 'percentCell' },
  netNewARRL3MCMGRPercent: { displayName: 'Net New ARR - L3M CMGR', type: 'percentCell' },
  netNewARRL12MCMGRPercent: { displayName: 'Net New ARR - L12M CMGR', type: 'percentCell' },
  netNewARRL6MCMGRPercent: { displayName: 'Net New ARR - L6M CMGR', type: 'percentCell' },
  magicNumber: { displayName: 'Magic Number', type: 'number' },
  cacPayback: { displayName: 'CAC Payback', type: 'number' },
  customers: { displayName: 'Customers', type: 'number' },
  customersGrowth: { displayName: 'Customers - Growth', type: 'percentCell' },
  customersYoYGrowth: { displayName: 'Customers - YoY Growth', type: 'percentCell' },
  customersL3MCMGR: { displayName: 'Customers - L3M CMGR', type: 'percentCell' },
  customersL6MCMGR: { displayName: 'Customers - L6M CMGR', type: 'percentCell' },
  customersL12MCMGR: { displayName: 'Customers - L12M CMGR', type: 'percentCell' },
  newCustomers: { displayName: 'New Customers', type: 'number' },
  churnedCustomers: { displayName: 'Churned Customers', type: 'number' },
  quickRatio: { displayName: 'Quick Ratio', type: 'percentCell' },
  totalHeadcount: { displayName: 'Total Headcount', type: 'number' },
  totalHeadcountGrowth: { displayName: 'Total Headcount - Growth', type: 'percentCell' },
  totalHeadcountYoYGrowth: { displayName: 'Total Headcount - YoY Growth', type: 'percentCell' },
  totalHeadcountL3MCMGR: { displayName: 'Total Headcount - L3M CMGR', type: 'percentCell' },
  totalHeadcountL6MCMGR: { displayName: 'Total Headcount - L6M CMGR', type: 'percentCell' },
  totalHeadcountL12MCMGR: { displayName: 'Total Headcount - L12M CMGR', type: 'percentCell' },
  rndHeadcount: { displayName: 'R&D Headcount', type: 'number' },
  cogsHeadcount: { displayName: 'COGS Headcount', type: 'number' },
  snmHeadcount: { displayName: 'S&M Headcount', type: 'number' },
  gnaHeadcount: { displayName: 'G&A Headcount', type: 'number' },

  cogs: { displayName: 'COGS ($)', type: 'currencyCell' },
  snmExpenses: { displayName: 'S&M Expenses', type: 'currencyCell' },
  rndExpenses: { displayName: 'R&D Expenses', type: 'currencyCell' },
  gnaExpenses: { displayName: 'G&A Expenses', type: 'currencyCell' },
  operatingExpenses: { displayName: 'Operating Expenses', type: 'currencyCell' },
}

type RowCells = NumberCell | HorizontalChevronCell | HeaderCell | CurrencyCell | DefaultCellTypes | PercentCell

function getRowFor(data: FinancialDataV2[], colIds: string[], key: string): Row<RowCells> {
  const details = keyToHeader[key]
  const headerName = details.displayName || 'N/A'
  return {
    rowId: key,
    cells: [
      ...colIds.map<RowCells>((colId, idx) => {
        if (idx === 0) return nonEditable({ type: 'headerCell', text: headerName })
        const item = findDataForColId(colId, data)
        let value = item?.[key] || ''
        return { type: details.type, value, className: details.className || 'cell', nanToZero: true }
      }),
    ],
    height: 38,
  }
}

function createHeaderRow(name: string, colIds: string[]): Row<RowCells> {
  return {
    rowId: `header-${name}`,
    cells: [
      ...colIds.map<RowCells>((colId, idx) => {
        const text = idx === 0 ? name : ''
        return nonEditable({ type: 'headerCell', text, className: 'header-section' })
      }),
    ],
    height: 50,
  }
}

const getRows = (data: FinancialDataV2[], cols: Column[]): Row<RowCells>[] => {
  const colIds = cols.map((col) => col.columnId.toString())
  return [
    headerRow(cols),
    createHeaderRow('Financials', colIds),
    getRowFor(data, colIds, 'totalRevenue'),
    getRowFor(data, colIds, 'totalRevGrowthPercent'),
    getRowFor(data, colIds, 'startingARR'),
    getRowFor(data, colIds, 'newSalesARR'),
    getRowFor(data, colIds, 'expansionARR'),
    getRowFor(data, colIds, 'resurrectedARR'),
    getRowFor(data, colIds, 'contractionARR'),
    getRowFor(data, colIds, 'churnedARR'),
    getRowFor(data, colIds, 'totalARR'),
    getRowFor(data, colIds, 'arrGrowthPercent'),
    getRowFor(data, colIds, 'totalGrossProfit'),
    getRowFor(data, colIds, 'grossMarginPercent'),
    getRowFor(data, colIds, 'totalEBITDA'),
    getRowFor(data, colIds, 'eBITDAMarginPercent'),
    getRowFor(data, colIds, 'totalFCF'),
    getRowFor(data, colIds, 'fcfMarginPercent'),
    createHeaderRow('Operating Expense Metrics', colIds),
    getRowFor(data, colIds, 'cogs'),
    getRowFor(data, colIds, 'snmExpenses'),
    getRowFor(data, colIds, 'rndExpenses'),
    getRowFor(data, colIds, 'gnaExpenses'),
    getRowFor(data, colIds, 'operatingExpenses'),
    getRowFor(data, colIds, 'gnaAsPercentOfRev'),
    getRowFor(data, colIds, 'snmAsPercentOfRev'),
    getRowFor(data, colIds, 'rndAsPercentOfRev'),
    createHeaderRow('Growth Efficiency Metrics', colIds),
    getRowFor(data, colIds, 'grossRetentionPercent'),
    getRowFor(data, colIds, 'netRetentionPercent'),
    getRowFor(data, colIds, 'salesEfficiencyMultiple'),
    getRowFor(data, colIds, 'netNewARR'),
    getRowFor(data, colIds, 'netNewARRGrowthPercent'),
    getRowFor(data, colIds, 'netNewARRYoYGrowthPercent'),
    getRowFor(data, colIds, 'netNewARRL3MCMGRPercent'),
    getRowFor(data, colIds, 'netNewARRL12MCMGRPercent'),
    getRowFor(data, colIds, 'netNewARRL6MCMGRPercent'),
    createHeaderRow('Efficiency Metrics', colIds),
    getRowFor(data, colIds, 'arrPerEmployee'),
    getRowFor(data, colIds, 'annualizedOpexPerEmployee'),
    getRowFor(data, colIds, 'burnMultiple'),
    getRowFor(data, colIds, 'operatingIncomePercent'),
    getRowFor(data, colIds, 'ruleOf40LTMFCF'),
    getRowFor(data, colIds, 'ruleOf40LTMEbitda'),
    getRowFor(data, colIds, 'salesAndMarketingYield'),
    getRowFor(data, colIds, 'implied5yrLTVPerCAC'),
    getRowFor(data, colIds, 'magicNumber'),
    getRowFor(data, colIds, 'cacPayback'),
    getRowFor(data, colIds, 'customers'),
    getRowFor(data, colIds, 'customersGrowth'),
    getRowFor(data, colIds, 'customersYoYGrowth'),
    getRowFor(data, colIds, 'customersL3MCMGR'),
    getRowFor(data, colIds, 'customersL6MCMGR'),
    getRowFor(data, colIds, 'customersL12MCMGR'),
    getRowFor(data, colIds, 'newCustomers'),
    getRowFor(data, colIds, 'churnedCustomers'),
    getRowFor(data, colIds, 'quickRatio'),
    getRowFor(data, colIds, 'totalHeadcount'),
    getRowFor(data, colIds, 'totalHeadcountGrowth'),
    getRowFor(data, colIds, 'totalHeadcountYoYGrowth'),
    getRowFor(data, colIds, 'totalHeadcountL3MCMGR'),
    getRowFor(data, colIds, 'totalHeadcountL6MCMGR'),
    getRowFor(data, colIds, 'totalHeadcountL12MCMGR'),
    getRowFor(data, colIds, 'rndHeadcount'),
    getRowFor(data, colIds, 'cogsHeadcount'),
    getRowFor(data, colIds, 'snmHeadcount'),
    getRowFor(data, colIds, 'gnaHeadcount'),
  ]
}

function findDataForColId(colId: string, data: FinancialDataV2[]) {
  const type = getTypeFromColId(colId)
  if (type.includes('tm')) return data.find((item) => item.type === type)
  const [year, quarter] = getYearQuarter(colId)
  const item = data.find((item) => item.year === year && (item.quarter || undefined) === quarter)
  return item
}

function getYearQuarter(colId: string): [number, number | undefined] {
  const [year, quarter] = colId.split('-')
  const outQuarter = quarter ? parseInt(quarter[1]) : undefined
  return [parseInt(year), outQuarter]
}

function getTypeFromColId(id: string) {
  if (id.includes('tm')) return id
  if (id.includes('Q')) return 'quarterly'
  return 'yearly'
}

type FinancialInputProps = {
  submit: (data: FinancialDataV2[]) => Promise<void>
  initState: FinancialDataV2[]
}

const FinancialInput: FunctionComponent<FinancialInputProps> = ({ submit, initState }) => {
  const [data, setData] = useState<FinancialDataV2[]>([...initState])
  const [sending, setSending] = useState<boolean>(false)
  const [columns, setCols] = useState<Column[]>(getColumns().filter((predicate) => !predicate.columnId.toString().includes('-')))
  const rows = getRows(data, columns)
  const location = useLocation()

  const handleChanges = (changes: CellChange<RowCells>[]) => {
    let updateData = [...data]

    changes.forEach((change) => {
      const fieldName = change.rowId
      const colId = change.columnId.toString()
      const oldItem = findDataForColId(colId, updateData)
      if (change.type === 'horizontalChevron') {
        const search = `${change.newCell.columnId.toString()}-`
        if (change.newCell.isExpanded) {
          const colsToAdd = getColumns().filter((col) => col.columnId.toString().includes(search))
          // remove header col
          const newCols = [...columns.slice(1), ...colsToAdd]
          // sort by name without header col. this addes new cols in the correct order after the year
          newCols.sort((a, b) => a.columnId.toString().localeCompare(b.columnId.toString()))
          // add header col back
          setCols([columns[0], ...newCols])
        } else {
          setCols(columns.filter((col) => !col.columnId.toString().includes(search)))
        }
        return
      }
      let newValue = (change as CellChange<any>).newCell.value
      if (change.type === 'percentCell') {
        newValue = newValue / 100
      }
      if (!oldItem) {
        const [year, quarter] = getYearQuarter(colId)
        updateData.push({
          type: getTypeFromColId(colId),
          year: year,
          quarter: quarter,
          [fieldName]: newValue,
        })
      } else {
        updateData = updateData.map((item) => {
          if (item === oldItem) {
            return {
              ...item,
              [fieldName]: newValue,
            }
          }
          return item
        })
      }
    })
    setData(updateData)
  }

  return (
    <>
      {location.pathname.includes('financials') && <CompanyHeader />}
      <GlassCard
        header='Financials'
        level={0}
        headerButtons={
          <Flex>
            <ExportButton data={{}} type='fin' filename='Finantials.xlsx' />
            <Button
              isLoading={sending}
              loadingText='Saving...'
              variation='primary'
              size='small'
              className='save'
              onClick={() => {
                setSending(true)
                submit(data)
                  .catch((e) => console.error('submit error', e))
                  .finally(() => setSending(false))
              }}
            >
              Save changes
            </Button>
          </Flex>
        }
      >
        <ScrollView className='financialsGrid'>
          <ReactGrid
            rows={rows}
            columns={columns}
            onCellsChanged={handleChanges as any}
            customCellTemplates={{
              horizontalChevron: new HorizontalChevronCellTemplate(),
              currencyCell: new CurrencyCellTemplate(),
              multipleCell: new MultipleCellTemplate(),
              percentCell: new PercentCellTemplate(),
              headerCell: new HeaderCellTemplate(),
            }}
            enableRangeSelection
            enableFillHandle
            stickyLeftColumns={1}
          />
        </ScrollView>
      </GlassCard>
    </>
  )
}

export default FinancialInput
